import React, { Component } from 'react'
import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import WistiaPlayer from 'react-player'
import Layout from '../components/Layout'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'
import DiversityBadge from '../components/DiversityBadge'

import 'isomorphic-fetch'

export default class Careers extends Component {
    render() {
        // const careerPhotos = this.props.data.pagesJson.images

        return (
            <Layout
                bodyClass="careers"
                title="Career Opportunities | HomeSpotter"
                description="We are looking for quality people to join our team to help us co-create the future of home search and collaboration together"
            >
                <OurCompanyNavigationRow location={this.props.location} />
                <div className="ourcompany careers col-12">
                    <header>
                        <h1>What’s it like to work at HomeSpotter?</h1>
                        <a href="#open-jobs" className="button button-primary">
                            See Open Jobs
                        </a>
                    </header>
                    <h2>Our core values</h2>
                    <WistiaPlayer
                        url="https://homespotter.wistia.com/medias/gtfbiz8c85"
                        width="100%"
                    />
                    <div className="careers-copy">
                        {/* <h2>Who we are, and who we want to be</h2> */}
                        {/* <p>
                            We're on a mission to make working life simpler,
                            more pleasant and more productive — for everyone.
                        </p> */}

                        {/* <p>
                            We’re big believers in community, and housing. Our
                            tools help delight real estate agents and their
                            clients, lorem ipsum sit dolar amet. lorem ipsum sit
                            dolar amet.
                        </p> */}

                        {/* <div className="quote">
                            <p className="quote-copy">
                                “I love working at HomeSpotter because lorem
                                ipsum sit dolar amet lorem sit dolar.”
                            </p>
                            <div className="quote-source">Attribution</div>
                        </div> */}
                    </div>

                    {/* <div className="grid-container">
                        <div className="Photo-1">
                            <Img
                                fluid={careerPhotos[0].childImageSharp.fluid}
                            />
                        </div>
                        <div className="Photo-2">
                            <Img
                                fluid={careerPhotos[1].childImageSharp.fluid}
                            />
                        </div>
                        <div className="Photo-3">
                            <Img
                                fluid={careerPhotos[2].childImageSharp.fluid}
                            />
                        </div>
                        <div className="Photo-4">
                            <Img
                                fluid={careerPhotos[3].childImageSharp.fluid}
                            />
                        </div>
                    </div> */}

                    <div className="careers-copy">
                        {/* <h2>Our core values</h2> */}

                        {/* <div className="why-work-here">
                            <h2>Why work here?</h2>
                            <div className="container">
                                <p>
                                    We’re all different. We’re all equal. We
                                    build bridges, not walls. We have bad days.
                                    We make mistakes, and learn from them. It’s
                                    okay. We forgive ourselves and others.
                                </p>
                                <ul className="benefits-list">
                                    <li>
                                        Medical, Dental, and Vision Coverage
                                    </li>
                                    <li>401K Matching</li>
                                    <li>15 days PTO + unlimited sick leave</li>
                                    <li>Parental leave</li>
                                </ul>
                            </div>
                        </div> */}
                        <div id="open-jobs" className="career-opportunities">
                            <div className="career-opportunities-content">
                                <h2>Career Opportunities</h2>

                                <p>
                                    We're hiring all around the US &amp; Canada,
                                    including in Minnesota. to see current
                                    positions, view{' '}
                                    <a href="https://www.lwolf.com/about/join-the-team">
                                        https://www.lwolf.com/about/join-the-team
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <DiversityBadge />
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
    {
        pagesJson(path: { eq: "/careers" }) {
            images {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
`
