import React from 'react'

const DiversityBadge = () => {
    return (
        <a
            target="_none"
            alt="MN tech diversity badge"
            name="MN tech diversity badge"
            href="https://mntechdiversity.com/"
        >
            <div className="diversitybadge" />
        </a>
    )
}

export default DiversityBadge
